import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import {
    Box,
    Flex,
    Heading,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';
import React from 'react';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    type: 'success' | 'fail';
}

export const EmailModal = ({ isOpen, onClose, type }: Props) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <Box textAlign="center" py={10} px={6}>
                    {type === 'success' ? (
                        <>
                            <CheckCircleIcon
                                boxSize={'50px'}
                                color={'green.500'}
                            />
                            <Heading as="h2" size="xl" mt={6} mb={2}>
                                Добре дошли!
                            </Heading>
                            <Text color={'gray.500'}>
                                Благодарим ви, че се заинтересовахте от нашия
                                продукт! Скоро някой от екипа ни ще се свърже с
                                вас, за да направим демо и да говорим за вашите
                                нужди и цели.
                            </Text>
                        </>
                    ) : (
                        <>
                            <Box display="inline-block">
                                <Flex
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    bg={'red.500'}
                                    rounded={'50px'}
                                    w={'55px'}
                                    h={'55px'}
                                    textAlign="center"
                                >
                                    <CloseIcon
                                        boxSize={'20px'}
                                        color={'white'}
                                    />
                                </Flex>
                            </Box>
                            <Heading as="h2" size="xl" mt={6} mb={2}>
                                Малък проблем
                            </Heading>
                            <Text color={'gray.500'}>
                                Имахме проблем с това да запишем имейла ви. Моля
                                опитайте отново, обещаваме да бъде по-добре 🙂
                            </Text>
                        </>
                    )}
                </Box>
            </ModalContent>
        </Modal>
    );
};
