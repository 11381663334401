import {
    Box,
    Button,
    chakra,
    Flex,
    Image,
    SimpleGrid,
    Text,
} from '@chakra-ui/react';
import React from 'react';
import noInvoicesImage from '../images/no-invoices.png';
import macMock from '../images/mac-mock.png';
import takePictureImage from '../images/takePicture.png';

export const Features = () => {
    return (
        <Flex justifyContent="center" alignItems="center">
            <Box
                bg="white"
                _dark={{
                    bg: 'gray.800',
                }}
                px={8}
                py={20}
                mx="auto"
            >
                <SimpleGrid
                    alignItems="start"
                    columns={{
                        base: 1,
                        md: 2,
                    }}
                    mb={24}
                    spacingY={{
                        base: 10,
                        md: 32,
                    }}
                    spacingX={{
                        base: 10,
                        md: 24,
                    }}
                >
                    <Box>
                        <chakra.h2
                            mb={4}
                            fontSize={{
                                base: '2xl',
                                md: '4xl',
                            }}
                            fontWeight="extrabold"
                            letterSpacing="tight"
                            textAlign={{
                                base: 'center',
                                md: 'left',
                            }}
                            color="gray.900"
                            _dark={{
                                color: 'gray.400',
                            }}
                            lineHeight={{
                                md: 'shorter',
                            }}
                        >
                            Започни за{' '}
                            <Text as={'span'} color={'orange.400'}>
                                0
                            </Text>{' '}
                            време
                        </chakra.h2>
                        <chakra.p
                            mb={5}
                            textAlign={{
                                base: 'center',
                                sm: 'left',
                            }}
                            color="gray.600"
                            _dark={{
                                color: 'gray.400',
                            }}
                            fontSize={{
                                md: 'lg',
                            }}
                        >
                            Забрави за обучения и дълги процедури по интеграция.
                            Изтегли приложението ни, влез в акаунта си и до 10
                            секунди можеш да забравиш главоболието от фактурите!
                        </chakra.p>
                        <Button
                            w={{
                                base: 'full',
                                sm: 'auto',
                            }}
                            size="lg"
                            borderRadius={'full'}
                            bg="blackAlpha.800"
                            _dark={{
                                bg: 'gray.700',
                            }}
                            _hover={{
                                bg: 'blackAlpha.900',
                                _dark: {
                                    bg: 'gray.600',
                                },
                            }}
                            color="gray.100"
                            as="a"
                            href={'#footer-contact-form'}
                        >
                            Научи повече
                        </Button>
                    </Box>

                    <Box w="full" h="full" maxH={'450px'}>
                        <Image
                            src={noInvoicesImage}
                            h="full"
                            w="full"
                            fit={'contain'}
                        />
                    </Box>
                </SimpleGrid>
                <SimpleGrid
                    alignItems="center"
                    columns={{
                        base: 1,
                        md: 2,
                    }}
                    flexDirection="column-reverse"
                    mb={24}
                    spacingY={{
                        base: 10,
                        md: 32,
                    }}
                    spacingX={{
                        base: 10,
                        md: 24,
                    }}
                >
                    <Box
                        order={{
                            base: 'initial',
                            md: 2,
                        }}
                    >
                        <chakra.h2
                            mb={4}
                            fontSize={{
                                base: '2xl',
                                md: '4xl',
                            }}
                            fontWeight="extrabold"
                            letterSpacing="tight"
                            textAlign={{
                                base: 'center',
                                md: 'left',
                            }}
                            color="gray.900"
                            _dark={{
                                color: 'gray.400',
                            }}
                            lineHeight={{
                                md: 'shorter',
                            }}
                        >
                            Работи ето така 🤌
                        </chakra.h2>
                        <chakra.p
                            mb={5}
                            textAlign={{
                                base: 'center',
                                sm: 'left',
                            }}
                            color="gray.600"
                            _dark={{
                                color: 'gray.400',
                            }}
                            fontSize={{
                                md: 'lg',
                            }}
                        >
                            Просто насочи телефона си към фактурата и остави ние
                            да свършим всичко останало. Това е! Фактурата отива
                            директно във вашия финансов и счетоводен отдел.
                        </chakra.p>
                        <Button
                            w={{
                                base: 'full',
                                sm: 'auto',
                            }}
                            size="lg"
                            borderRadius={'full'}
                            bg="blackAlpha.800"
                            _dark={{
                                bg: 'gray.700',
                            }}
                            _hover={{
                                bg: 'blackAlpha.900',
                                _dark: {
                                    bg: 'gray.600',
                                },
                            }}
                            color="gray.100"
                            as="a"
                            href={'#footer-contact-form'}
                        >
                            Научи повече
                        </Button>
                    </Box>
                    <Box w="full" h="full">
                        <Image
                            src={takePictureImage}
                            h="full"
                            w="full"
                            maxH={'450px'}
                            fit={'contain'}
                        />
                    </Box>
                </SimpleGrid>
                <SimpleGrid
                    alignItems="start"
                    columns={{
                        base: 1,
                        md: 2,
                    }}
                    mb={24}
                    spacingY={{
                        base: 10,
                        md: 32,
                    }}
                    spacingX={{
                        base: 10,
                        md: 24,
                    }}
                >
                    <Box>
                        <chakra.h2
                            mb={4}
                            fontSize={{
                                base: '2xl',
                                md: '4xl',
                            }}
                            fontWeight="extrabold"
                            letterSpacing="tight"
                            textAlign={{
                                base: 'center',
                                md: 'left',
                            }}
                            color="gray.900"
                            _dark={{
                                color: 'gray.400',
                            }}
                            lineHeight={{
                                md: 'shorter',
                            }}
                        >
                            Като на магия 🧙‍♂️
                        </chakra.h2>
                        <chakra.p
                            mb={5}
                            textAlign={{
                                base: 'center',
                                sm: 'left',
                            }}
                            color="gray.600"
                            _dark={{
                                color: 'gray.400',
                            }}
                            fontSize={{
                                md: 'lg',
                            }}
                        >
                            Забрави за ръчното изваждане на данни от фактурата.
                            Нашият изкуствен интелект автоматично ще извади над
                            80% от информацията. Пропуснал е нещо? Няма проблеми
                            просто цъкни върху автоматично извлечените текстови
                            кутийки.
                        </chakra.p>
                        <Button
                            w={{
                                base: 'full',
                                sm: 'auto',
                            }}
                            size="lg"
                            borderRadius={'full'}
                            bg="blackAlpha.800"
                            _dark={{
                                bg: 'gray.700',
                            }}
                            _hover={{
                                bg: 'blackAlpha.900',
                                _dark: {
                                    bg: 'gray.600',
                                },
                            }}
                            color="gray.100"
                            as="a"
                            href={'#footer-contact-form'}
                        >
                            Научи повече
                        </Button>
                    </Box>

                    <Box w="full">
                        <Image
                            src={macMock}
                            w={'750px'}
                            // h="full"
                            // w="full"
                            fit={'contain'}
                        />
                    </Box>
                </SimpleGrid>
            </Box>
        </Flex>
    );
};
