import React from 'react';
import {
    Flex,
    Container,
    Stack,
    Button,
    Box,
    chakra,
    Icon,
    Input,
    VisuallyHidden,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react';

import { MainPageIllustration } from '../components/MainPageIllustration';
import WithSubnavigation from '../components/Navigation';
import { Features } from '../components/Features';
import { FooterContactForm } from '../components/FooterContactForm';
import Footer from '../components/Footer';
import { EmailModal } from '../components/EmailModal';
import { useEmailModal } from '../helpers/useEmailModal';
import { Helmet } from 'react-helmet';

export const Main = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Fakturki.bg</title>
            </Helmet>
            <WithSubnavigation />
            <Container maxW={'7xl'}>
                <Hero />
                <Features />
                <FooterContactForm />
            </Container>

            <Footer />
        </>
    );
};

const Hero = () => {
    const { handleChange, handleSubmit, isOpen, onClose, modalType } =
        useEmailModal();

    const Feature = props => (
        <Flex
            alignItems="center"
            color={null}
            _dark={{
                color: 'white',
            }}
        >
            <Icon
                boxSize={4}
                mr={1}
                color="green.600"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                ></path>{' '}
                12
            </Icon>
            {props.children}
        </Flex>
    );

    return (
        <>
            <EmailModal type={modalType} isOpen={isOpen} onClose={onClose} />
            <Box px={4} py={32} mx="auto">
                <Box
                    w={{
                        base: 'full',
                        md: 11 / 12,
                        xl: 8 / 12,
                    }}
                    textAlign={{
                        base: 'left',
                        md: 'center',
                    }}
                    mx="auto"
                >
                    <chakra.h1
                        mb={3}
                        fontSize={{
                            base: '4xl',
                            md: '5xl',
                        }}
                        fontWeight={{
                            base: 'bold',
                            md: 'extrabold',
                        }}
                        color="gray.900"
                        _dark={{
                            color: 'gray.100',
                        }}
                        lineHeight="shorter"
                    >
                        Без повече главоболие от фактури.
                    </chakra.h1>
                    <chakra.p
                        mb={6}
                        fontSize={{
                            base: 'lg',
                            md: 'xl',
                        }}
                        color="gray.500"
                        lineHeight="base"
                    >
                        Фактурки.бг спестява на бизнеса ви пари и време от
                        разходи, фактури и толкова много още. Плати, снимай,
                        забрави.
                    </chakra.p>
                    <chakra.form w="full" mb={6}>
                        <VisuallyHidden>Вашият имейл</VisuallyHidden>
                        <Box
                            display={{
                                base: 'block',
                                lg: 'none',
                            }}
                        >
                            <Input
                                size="lg"
                                color="blackAlpha.800"
                                type="email"
                                placeholder="Въведете имейла си..."
                                onChange={handleChange}
                                bg="white"
                            />
                            <Button
                                w="full"
                                mt={2}
                                color="white"
                                variant="solid"
                                backgroundColor="blackAlpha.900"
                                size="lg"
                                onClick={handleSubmit}
                                type="submit"
                            >
                                Стартирай
                            </Button>
                        </Box>
                        <InputGroup
                            size="lg"
                            w="full"
                            display={{
                                base: 'none',
                                lg: 'flex',
                            }}
                        >
                            <Input
                                size={'lg'}
                                color="brand.900"
                                type="email"
                                placeholder="Въведете имейла си..."
                                bg="white"
                                onChange={handleChange}
                                roundedLeft={20}
                                roundedRight={22}
                                pl={7}
                            />
                            <InputRightElement w="auto">
                                <Button
                                    color="white"
                                    variant="solid"
                                    backgroundColor={'black'}
                                    size="lg"
                                    type="submit"
                                    roundedLeft={0}
                                    roundedRight={21}
                                    borderWidth={0}
                                    onClick={handleSubmit}
                                >
                                    Стартирай
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </chakra.form>
                    <Stack
                        display="flex"
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                        justifyContent={{
                            base: 'start',
                            md: 'center',
                        }}
                        mb={3}
                        spacing={{
                            base: 2,
                            md: 8,
                        }}
                        fontSize="xs"
                        color="gray.600"
                    >
                        {/* TODO */}
                        {/* <Feature>No credit card required</Feature>
    <Feature>14 days free</Feature>
    <Feature>Cancel anytime</Feature> */}
                    </Stack>
                </Box>
                <Flex w={'full'}>
                    <MainPageIllustration
                        height={{ sm: '24rem', lg: '28rem' }}
                        mt={{ base: 12, sm: 16 }}
                    />
                </Flex>
            </Box>
        </>
    );
};

export default Main;
