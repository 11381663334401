import {
    Box,
    Button,
    chakra,
    Flex,
    Input,
    InputGroup,
    InputRightElement,
    VisuallyHidden,
} from '@chakra-ui/react';
import React from 'react';
import { useEmailModal } from '../helpers/useEmailModal';
import { EmailModal } from './EmailModal';

export const FooterContactForm = () => {
    const { handleChange, handleSubmit, isOpen, onClose, modalType } =
        useEmailModal();

    return (
        <>
            <EmailModal isOpen={isOpen} onClose={onClose} type={modalType} />
            <Flex
                bg="blackAlpha.100"
                borderRadius={21}
                mb={50}
                alignItems="center"
                justifyContent={'center'}
                id="footer-contact-form"
            >
                <Flex
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    maxW={'700px'}
                    px={{
                        base: 4,
                        lg: 20,
                    }}
                    py={24}
                >
                    <chakra.h1
                        mb={6}
                        fontSize={{
                            base: '4xl',
                            md: '4xl',
                            lg: '5xl',
                        }}
                        fontWeight="bold"
                        color="brand.600"
                        _dark={{
                            color: 'gray.300',
                        }}
                        lineHeight="shorter"
                    >
                        Автоматизацията на финансовия ви отдел започва тук.
                    </chakra.h1>
                    <chakra.form w="full" mb={6}>
                        <VisuallyHidden>Вашият имейл</VisuallyHidden>
                        <Box
                            display={{
                                base: 'block',
                                lg: 'none',
                            }}
                        >
                            <Input
                                size="lg"
                                color="blackAlpha.800"
                                type="email"
                                placeholder="Въведете имейла си..."
                                onChange={handleChange}
                                bg="white"
                            />
                            <Button
                                w="full"
                                mt={2}
                                color="white"
                                backgroundColor="blackAlpha.900"
                                variant="solid"
                                size="lg"
                                onClick={handleSubmit}
                                type="submit"
                            >
                                Стартирай
                            </Button>
                        </Box>
                        <InputGroup
                            size="lg"
                            w="full"
                            display={{
                                base: 'none',
                                lg: 'flex',
                            }}
                        >
                            <Input
                                size="lg"
                                color="blackAlpha.800"
                                type="email"
                                placeholder="Въведете имейла си..."
                                bg="white"
                                onChange={handleChange}
                                roundedLeft={21}
                                roundedRight={21}
                                pl={7}
                            />
                            <InputRightElement w="auto">
                                <Button
                                    color="white"
                                    variant="solid"
                                    backgroundColor={'blackAlpha.800'}
                                    _hover={{
                                        backgroundColor: 'blackAlpha.900',
                                    }}
                                    size="lg"
                                    type="submit"
                                    onClick={handleSubmit}
                                    roundedLeft={0}
                                    roundedRight={21}
                                >
                                    Стартирай
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </chakra.form>
                    {/* <chakra.p
        pr={{
            base: 0,
            lg: 16,
        }}
        mb={4}
        fontSize="sm"
        color="brand.600"
        _dark={{
            color: 'gray.400',
        }}
        letterSpacing="wider"
    >
        Get the #1 Business Messenger and start delivering
        personalized experiences at every stage of the customer
        journey.
    </chakra.p> */}
                </Flex>
                {/* <Box>
        <Image
            src="https://images.unsplash.com/photo-1531548731165-c6ae86ff6491?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80"
            alt="3 women looking at a laptop"
            fit="cover"
            w="full"
            h={{
                base: 64,
                md: 'full',
            }}
            bg="gray.100"
            loading="lazy"
        />
    </Box> */}
            </Flex>
        </>
    );
};
