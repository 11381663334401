import { useDisclosure } from '@chakra-ui/react';
import { useState, useCallback } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export const useEmailModal = () => {
    const [email, setEmail] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [modalType, setModalType] = useState('success');

    const handleChange = event => {
        setEmail(event.target.value);
    };

    const openModal = useCallback(
        (type: 'success' | 'fail') => {
            setModalType(type);
            onOpen();
        },
        [onOpen]
    );

    const handleSubmit = useCallback(
        async ev => {
            ev.preventDefault();
            if (!email) {
                return;
            }
            const res = await addToMailchimp(email);
            console.log(res);
            if (res.result === 'success') {
                console.log('success??');

                openModal('success');
            } else {
                console.log('rip');

                openModal('fail');
            }
        },
        [email, openModal]
    );

    return {
        handleChange,
        handleSubmit,
        isOpen,
        onClose,
        modalType,
    };
};
